<template>
  <div class="w-100">
    <div class="w-100 rounded mb-3 p-3 shadow-sm" v-background-3>
      <div class="d-flex flex-column flex-lg-row">
        <div class="w-100 mr-3">
          <h5 class="mb-3">Tableau de bord</h5>
          <div v-background-1 class="rounded p-3 mb-1">
            <strong> {{ ncs.length }}</strong> écarts identifiés
          </div>
          <div v-background-1 class="rounded p-3 mb-1">
            <strong> {{ ncClotures }}</strong> écarts clôturés
          </div>
          <div v-background-1 class="rounded p-3">
            <strong> {{ ((ncClotures / ncs.length) * 100).toFixed(0) }}%</strong> Taux de clôture
          </div>
        </div>
        <div class="w-100 mt-3">
          <h6 class="m-0 ml-3">Evolution de la remontée des écarts</h6>
          <apexchart type="line" :options="options" :series="seriesNcs" height="200"></apexchart>
        </div>
      </div>
    </div>
    <div class="mb-3" v-if="loading">Chargement...</div>
    <div class="d-flex justify-content-between align-items-center mb-3" v-if="!loading">
      <div>
        <h5 class="m-0">
          Liste des écarts <span v-if="!old">{{ $moment().format("YYYY") }}</span>
        </h5>
        <div class="text-primary cursor-pointer" @click="getOldNcs" v-if="!old">Afficher les écarts plus anciens</div>
        <div class="text-primary cursor-pointer" @click="getNcs" v-if="old">Afficher les écarts récents</div>
      </div>
      <btn class="btn btn-primary" @click="createNcs()" :loading="loading" text="Ajouter" icon="plus" />
    </div>
    <div class="d-flex flex-column flex-lg-row">
      <filtre-select
        v-model="filterPilote"
        inputText="Pilote"
        :options="users"
        field="collaborateur_nom"
        class="mb-3 mr-3"
        :disabled="false"
      ></filtre-select>
      <filtre-select
        v-model="filterOrigine"
        inputText="Origine"
        :options="data.ncOrigines"
        class="mb-3"
        :disabled="false"
      ></filtre-select>
    </div>
    <div class="mb-3" v-if="!loading">
      <div v-for="nc in ncNews" :key="'nc_new_' + nc.id" class="w-100">
        <ncsItem :nc="nc"></ncsItem>
      </div>
      <div v-for="nc in ncOlds" :key="'nc' + nc.id" class="w-100">
        <ncsItem :nc="nc"></ncsItem>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import ncsItem from "@/components/qsser/ncsItem.vue";
import btn from "@/components/bases/Button.vue";
import FiltreSelect from "@/components/bases/FiltreSelect.vue";
var fr = require("apexcharts/dist/locales/fr.json");

export default {
  components: { ncsItem, btn, FiltreSelect },
  data() {
    return {
      old: false,
      loading: false,
      filterPilote: null,
      filterOrigine: null,
    };
  },
  mounted() {
    this.getNcs();
    //this.getAffaires();
  },
  methods: {
    ...mapActions({
      _getNcs: "qsser/getNcs",
      _getOldNcs: "qsser/getOldNcs",
      _createNcs: "qsser/createNcs",
      //getAffaires: "affaires/getAffaires",
    }),
    getNcs: function () {
      this.loading = true;
      this._getNcs()
        .then(() => (this.old = false))
        .finally(() => (this.loading = false));
    },
    getOldNcs: function () {
      this.loading = true;
      this._getOldNcs()
        .then(() => (this.old = true))
        .finally(() => (this.loading = false));
    },
    createNcs: function () {
      this.loading = true;
      this._createNcs().finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapGetters({
      data: "qsser/data",
      users: "user/users",
    }),
    ...mapMultiRowFields("qsser", ["ncs", "actions"]),
    //...mapMultiRowFields("affaires", ["affaires"]),
    //...mapMultiRowFields("user", ["users"]),
    /* affairesList() {
      if (!this.affaires) return [];
      return this.affaires.map((affaire) => {
        return {
          id: affaire.id,
          description: affaire.affaire_identifiant + " - " + affaire.affaire_libelle,
        };
      });
    },*/
    ncNews() {
      return this.ncs.filter((nc) => nc.nc_new == true).reverse();
    },
    ncOlds() {
      let rep = [];
      rep = this.ncs.filter((nc) => nc.nc_new != true).reverse();
      if (this.filterPilote) rep = rep.filter((nc) => nc.nc_user_id == this.filterPilote);
      if (this.filterOrigine) rep = rep.filter((nc) => nc.nc_origine_id == this.filterOrigine);

      return rep;
    },
    ncClotures() {
      let counter = 0;
      this.ncs.forEach((nc) => {
        if (nc.nc_cloture == true) counter++;
      });
      return counter;
    },
    qwarkBorder() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
    seriesNcs() {
      if (!this.ncs || this.ncs.length < 1) return [];

      var startDate = this.$moment().startOf("year");
      var endDate = this.$moment();

      if (this.old) {
        startDate = this.$moment().startOf("year").subtract("year", 1);
        endDate = this.$moment().endOf("year").subtract("year", 1);
      }

      var startingMoment = startDate;
      var rep = [];
      var y = 0;

      while (startingMoment <= endDate) {
        this.ncs.forEach((nc) => {
          if (this.$moment(nc.nc_date).format("MM/YYYY") === startingMoment.format("MM/YYYY")) y = y + 1;
        });
        rep.push({ x: startingMoment.format("YYYY-MM"), y: y });
        startingMoment.add(1, "months");
      }
      return [
        {
          name: "Ecarts",
          type: "line",
          data: rep,
        },
      ];
    },
    options() {
      return {
        chart: {
          background: "transparent",
          locales: [fr],
          defaultLocale: "fr",
          width: "100%",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: "zoom",
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "solid",
        },
        xaxis: {
          type: "datetime",
        },
        labels: {
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
        theme: {
          mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
        },
      };
    },
  },
};
</script>
<style></style>
