<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="w-100 d-flex justify-content-between align-items-center p-2">
        <div v-if="!nc.nc_libelle">
          <span class="mr-2">Ecart n°{{ nc.id }}</span>
          <span class="text-primary">Nouvel écart à saisir</span>
        </div>
        <div v-if="nc.nc_libelle" class="d-flex align-items-center">
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-success mr-2"
            fill="#28a745"
            v-if="nc.nc_cloture"
          ></BaseIcon>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-danger mr-2"
            fill="#dc3545"
            v-if="!nc.nc_cloture"
          ></BaseIcon>
          <div>
            <span class="mr-2">Ecart n°{{ nc.id }}</span>
            <span class="text-muted">{{ nc.nc_libelle }}</span>
          </div>
        </div>
        <div>
          <small class="text-muted">
            <span class="" v-if="nc.nc_type_id == 1">{{
              data.ncTypes.filter((x) => x.id == nc.nc_type_id)[0].description
            }}</span>
            <span class="text-danger" v-if="nc.nc_type_id == 2">{{
              data.ncTypes.filter((x) => x.id == nc.nc_type_id)[0].description
            }}</span>
            <span class="ml-2">{{ $moment(nc.nc_date).format("DD/MM/YYYY") }}</span>
          </small>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="d-flex align-items-center p-3 justify-content-left" style="max-width: 100%; overflow: auto">
        <menuItem title="Infos" @click="tab = 'informations'" :active="tab === 'informations'" icon="info"></menuItem>
        <menuItem title="Causes" @click="tab = 'causes'" :active="tab === 'causes'" icon="crosshair"></menuItem>
        <menuItem
          title="Dérogation"
          @click="tab = 'derogation'"
          :active="tab === 'derogation'"
          icon="bell-off"
        ></menuItem>
        <menuItem title="actions" @click="tab = 'actions'" :active="tab === 'actions'" icon="check-circle"></menuItem>
        <menuItem title="documents" @click="tab = 'documents'" :active="tab === 'documents'" icon="file"></menuItem>
        <menuItem title="clôture" @click="tab = 'cloture'" :active="tab === 'cloture'" icon="file"></menuItem>
      </div>
      <base-form
        deleteBtn
        :loading="loading"
        @click="updateNcs(nc)"
        @delete="deleteNcs(nc)"
        class=""
        v-if="tab !== 'documents' && tab !== 'actions'"
      >
        <div v-if="tab === 'informations'">
          <h5 class="m-0">Informations</h5>
          <div class="text-muted">
            <small>Renseignez les champs nécessaires à l'identification de l'écart</small>
          </div>
          <div class="text-justify mt-3 ml-3 mr-3 pr-md-4">
            <div class="d-flex align-items-center">
              <BaseIcon name="flag" width="100" height="100" class="d-none d-md-block mr-3 mr-md-4"></BaseIcon>
              <div>
                <div class="mb-3">
                  <small>
                    <p>
                      Un écart dit <strong>« majeur »</strong> présente un risque sérieux pour la fiabilité des
                      résultats, la qualité des livrables, la sureté de l’installation ou la sécurité et la
                      radioprotection du personnel et entraine la suspension de la prestation qui ne pourra être reprise
                      qu’après traitement. Chez EDF, une NC sur une AIP est majeure.
                    </p>
                    <p>
                      Un écart <strong>« mineur »</strong> décelée n’ayant pas d’incidence directe sur la qualité de la
                      prestation ou la sécurité des hommes et de l’environnement fera l’objet d’un traitement continu
                      (sans interruption de production). Cela représente l’opportunité d’améliorer en continue le
                      produit du système de management et de la satisfaction des clients.
                    </p>
                  </small>
                </div>
              </div>
            </div>
          </div>
          <base-form-row row>
            <base-input v-model="nc.nc_libelle" inputText="Libellé" :errors="feedback.nc_libelle"></base-input>
            <base-inputDatePicker
              v-model="nc.nc_date"
              inputText="Date"
              :errors="feedback.nc_date"
            ></base-inputDatePicker>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Type"
              v-model.number="nc.nc_type_id"
              :options="data.ncTypes"
              :errors="feedback.nc_type_id"
            ></base-select>
            <base-select
              inputText="Origine"
              v-model.number="nc.nc_origine_id"
              :options="data.ncOrigines"
              :errors="feedback.nc_origine_id"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Affaire"
              v-model.number="nc.nc_affaire_id"
              :options="affaires"
              :errors="feedback.nc_affaire_id"
              :disabled="false"
            ></base-select>
            <base-select
              inputText="Société"
              v-model.number="nc.nc_societe_id"
              :options="data.societes"
              :errors="feedback.nc_societe_id"
              :disabled="false"
            ></base-select>
          </base-form-row>
          <div class="mt-1">
            <small>
              <p class="text-muted text-justify">Indiquez ici si l'écart identifié est lié à une affaire.</p>
            </small>
          </div>
          <base-form-row row>
            <select-collaborateur class="mb-3" v-model.number="nc.nc_user_id" :text="'Pilote'"></select-collaborateur>
            <base-input
              v-model="nc.nc_reference"
              inputText="Indiquez le référentiel applicable"
              :errors="feedback.nc_reference"
            ></base-input>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              class="mb-3"
              v-model="nc.nc_faits"
              text="Description des faits"
              :errors="feedback.nc_faits"
            ></base-input-area>
          </base-form-row>
        </div>

        <div class="" v-if="tab === 'causes'">
          <h5 class="m-0">Identification des causes</h5>
          <div class="text-muted">
            <small>Identifiez les causes pour éviter que le problème se reproduise</small>
          </div>
          <base-form-row row>
            <base-input-area
              v-model="nc.nc_causes"
              text="Identification des causes"
              :errors="feedback.nc_causes"
            ></base-input-area>
          </base-form-row>
          <base-form-row row>
            <base-select
              class="mb-3"
              inputText="Choisir une catégorie"
              v-model.number="nc.nc_cause_id"
              :options="data.causes"
              :errors="feedback.nc_cause_id"
            ></base-select>
          </base-form-row>
        </div>
        <div class="" v-if="tab === 'derogation'">
          <h5 class="m-0">Dérogation</h5>
          <div class="text-muted">
            <small>Avez-vous obtenu une dérogation au référentiel ?</small>
          </div>
          <base-form-row row>
            <Checkbox
              id="4"
              class="mt-4"
              v-model="nc.nc_derogation"
              text="Dérogation aux exigences du référentiel"
              :errors="feedback.nc_derogation"
            ></Checkbox>
          </base-form-row>
          <div class="mt-2">
            <small>
              <p class="text-muted text-justify">
                Une non-conformité sur une AIP (EDF) est majeure et nécessite toujours une demande de dérogation client.
                Une non-conformité majeure, sans action corrective, même hors AIP, nécessite également une demande de
                dérogation.
              </p>
            </small>
          </div>
          <base-form-row row v-if="nc.nc_derogation">
            <base-input
              v-model="nc.nc_derogation_description"
              inputText="Objet de la dérogation"
              :errors="feedback.nc_derogation_description"
            ></base-input>
          </base-form-row>
          <base-form-row row v-if="nc.nc_derogation">
            <base-input-area
              class="mb-3"
              v-model="nc.nc_derogation_justification"
              text="Justification de la dérogation"
              :errors="feedback.nc_derogation_justification"
            ></base-input-area>
          </base-form-row>
        </div>
        <div v-if="tab === 'cloture'" class="">
          <h5 class="m-0">Clôture</h5>
          <div class="text-muted">
            <small>Clôturez l'écart lorsque tout a été fait</small>
          </div>

          <base-form-row row>
            <Checkbox
              id="4"
              class="mt-4"
              v-model="nc.nc_cloture"
              text="Clôture de l'écart"
              :errors="feedback.nc_cloture"
            ></Checkbox>
          </base-form-row>
          <div class="mt-2 mb-3">
            <small>
              <p class="text-muted text-justify">
                Pour clôturer un écart, le pilote doit assurer que l'ensemble des dispositions sont prises (actions)
                pour garantir la conformité aux exigences (sauf dérogation acceptée par le client et le service QSSER)
                et éviter qu'un écart du même type se reproduise.
              </p>
            </small>
          </div>
        </div>
      </base-form>
      <div class="w-100 pl-md-3 pr-md-3" v-if="tab === 'documents'">
        <uploader
          v-if="nc.id"
          :id="'document_qsser_ncs' + nc.id"
          url="/upload/document"
          title="Déposer vos fichiers"
          :file_system="'QSSER/nc'"
          :file_field="'NC'"
          :file_key="nc.id"
        ></uploader>
      </div>
    </template>
    <template v-slot:actions>
      <actions-linked v-if="tab === 'actions'" :origine_system="21" :origine_id="nc.id"></actions-linked>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import Uploader from "@/components/bases/Uploader.vue";
import actionsLinked from "@/components/qsser/actionsLinked.vue";
import menuItem from "@/components/qsser/menuItem.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInput,
    BaseSelect,
    actionsLinked,
    Uploader,
    menuItem,
    SelectCollaborateur,
    Checkbox,
    BaseIcon,
  },
  data() {
    return {
      old: false,
      loading: false,
      feedback: {},
      tab: "informations",
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      _updateNcs: "qsser/updateNcs",
      _deleteNcs: "qsser/deleteNcs",
    }),
    updateNcs: function (nc) {
      this.feedback = {};
      this.loading = true;
      this._updateNcs(nc)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteNcs: function (nc) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteNcs(nc)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  props: {
    nc: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    actions: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
  },
  computed: {
    ...mapGetters({
      data: "qsser/data",
      affaires: "affaires/affaires",
    }),
  },
};
</script>
<style></style>
