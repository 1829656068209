<template>
  <div class="m-1 p-1 p-md-2 mb-5 rounded">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h5 class="m-0">Actions liées</h5>
      <ButtonCircle
        :loading="loading"
        size="18"
        name="plus"
        colorClass="btn-primary"
        @click="createActions({ action_origine_system_id: origine_system, action_origine_id: origine_id })"
      ></ButtonCircle>
    </div>
    <div v-for="action in actions" :key="action.id">
      <div v-if="action.action_origine_system_id === origine_system && action.action_origine_id === origine_id">
        <container-list-extend title="Visite" class="d-flex flex-column w-100 rounded">
          <template v-slot:title>
            <div class="w-100 d-flex justify-content-start align-items-center p-1 p-md-2">
              <div class="mr-2">
                <BaseIcon
                  name="circle"
                  width="10"
                  height="10"
                  class="text-success"
                  fill="currentColor"
                  v-if="action.action_etat_id === 3"
                ></BaseIcon>
                <BaseIcon
                  name="circle"
                  width="10"
                  height="10"
                  class="text-primary"
                  fill="currentColor"
                  v-if="action.action_etat_id === 2"
                ></BaseIcon>
                <BaseIcon
                  name="circle"
                  width="10"
                  height="10"
                  class="text-danger"
                  fill="currentColor"
                  v-if="action.action_etat_id === 1"
                ></BaseIcon>
              </div>
              <div>
                <span v-if="action.description" class="mr-2">{{ action.description }}</span>
                <span v-if="!action.description" class="mr-2 text-primary">Nouvelle action</span>
              </div>
            </div>
          </template>
          <template v-slot:form>
            <base-form deleteBtn @click="updateActions(action)" @delete="deleteActions(action)" :loading="loading">
            
              <base-form-row row>
                <base-select
                  inputText="Origine"
                  v-model.number="action.action_origineact_id"
                  :options="data.actionOrigineacts"
                  :errors="feedback.action_origineact_id"
                ></base-select>
              </base-form-row>

              <base-form-row row>
                <base-input
                  v-model="action.description"
                  inputText="Libellé"
                  :errors="feedback.description"
                ></base-input>
              </base-form-row>
              <base-form-row row>
                <base-input-area
                  v-model="action.action_details"
                  text="Détails de l'action"
                  :errors="feedback.action_details"
                ></base-input-area>
              </base-form-row>
              <base-form-row row>
                <base-inputDatePicker
                  v-model="action.action_debut"
                  inputText="Début"
                  :errors="feedback.action_debut"
                ></base-inputDatePicker>
                <base-inputDatePicker
                  v-model="action.action_delais"
                  inputText="Fin prévue"
                  :errors="feedback.action_delais"
                ></base-inputDatePicker>
              </base-form-row>
              <base-form-row row>
                <base-inputDatePicker
                  v-model="action.action_fin"
                  inputText="Date de fin"
                  :errors="feedback.action_fin"
                ></base-inputDatePicker>
              </base-form-row>
              <base-form-row row>
                <base-select
                  inputText="Etat"
                  v-model.number="action.action_etat_id"
                  :options="data.actionEtats"
                  :errors="feedback.action_etat_id"
                ></base-select>
                <base-select
                  inputText="Type"
                  v-model.number="action.action_type_id"
                  :options="data.actionTypes"
                  :errors="feedback.action_type_id"
                ></base-select>
              </base-form-row>
              <base-form-row row>
                <base-input-area
                  v-model="action.action_verif_critere"
                  text="Critères de vérification d'efficacité"
                  :errors="feedback.action_verif_critere"
                ></base-input-area>
              </base-form-row>
              <base-form-row row>
                <base-input-area
                  v-model="action.action_verif_commentaire"
                  text="Résultat de la vérification d'efficacité"
                  :errors="feedback.action_verif_commentaire"
                ></base-input-area>
              </base-form-row>
              <uploader
                v-if="action.id"
                :id="'document_qsser_actions' + action.id"
                url="/upload/document"
                title="Déposer vos fichiers"
                :file_system="'QSSER/actions'"
                :file_field="'action'"
                :file_key="action.id"
              ></uploader>
            </base-form>
          </template>
        </container-list-extend>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInput,
    BaseSelect,
    BaseIcon,
    ButtonCircle,
    Uploader,
  },
  data() {
    return {
      old: false,
      loading: false,
      feedback: {},
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      _createActions: "qsser/createActions",
      _updateActions: "qsser/updateActions",
      _deleteActions: "qsser/deleteActions",
    }),
    createActions: function (ac) {
      this.feedback = {};
      this.loading = true;
      this._createActions(ac)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    updateActions: function (ac) {
      this.feedback = {};
      this.loading = true;
      this._updateActions(ac)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteActions: function (ac) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteActions(ac)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  props: ["origine_system", "origine_id"],
  computed: {
    ...mapGetters({
      data: "qsser/data",
    }),
    ...mapMultiRowFields("qsser", ["actions"]),
    bg: function () {
      return this.$store.getters["colors/colors"].color2;
    },
  },
};
</script>
<style></style>
